const BRAND_INFO_LOCAL_STORAGE_KEY = "brand-info";
const PIP_DATA_STORAGE_KEY = "pip-data";
const isPipPaired = !!window.localStorage.getItem("pip-data") && !window.location.pathname.includes("/pair");

function showContent() {
  const allowed_routes = ["/login", "/pip-login", "/"];
  return window.location.pathname && allowed_routes.includes(window.location.pathname);
}

function _getContrastColour(hexcolor) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") hexcolor = hexcolor.slice(1);

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      .map((hex) => hex + hex)
      .join("");
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "#000000" : "#ffffff";
}

function sanitizeColour(string) {
  return string.replace(/[^#a-f0-9]/gi, "").substr(0, 7);
}

function pipWelcomePlaceholder(colour) {
  const content_placeholder = document.getElementById("content-placeholder");
  if (!content_placeholder) return;
  const pipWelcomePlaceholderElement = document.createElement("div");

  const html = `
    <div>
      <div class="my-6 flex justify-center" style="color: ${sanitizeColour(colour)}">
        <h2 class="text-3xl font-bold text-center">Welcome to our practice</h2>
      </div>
    </div>
    <div>
      <div class="centred-container">
        <div class="flex flex-col items-center">
          <div>
            <h3 class="heading-xl text-center text-xl">
              <div>Check in and fill in forms on this device</div>
            </h3>
          </div>
          <h4 class="heading-lg text-center text-l mt-6 flex items-center justify-center">
              Getting things ready
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="w-8 ml-3" viewBox="0 0 80 20">
                <circle cx="10" cy="10" r="10" class="fill-gray-900">
                  <animate attributeName="cx" from="10" to="40" dur="0.5s" calcMode="spline" keySplines="0.42 0 0.58 1" keyTimes="0;1" repeatCount="indefinite" />
                </circle>
                <circle cx="10" cy="10" r="0" class="fill-gray-900">
                  <animate attributeName="r" from="0" to="10" dur="0.5s" calcMode="spline" keySplines="0.42 0 0.58 1" keyTimes="0;1" repeatCount="indefinite" />
                </circle>
                <circle cx="40" cy="10" r="10"  class="fill-gray-900">
                  <animate attributeName="cx" from="40" to="70" dur="0.5s" calcMode="spline" keySplines="0.42 0 0.58 1" keyTimes="0;1" repeatCount="indefinite" />
                </circle>
                <circle cx="70" cy="10" r="10"  class="fill-gray-900">
                  <animate attributeName="r" from="10" to="0" dur="0.5s" calcMode="spline" keySplines="0.42 0 0.58 1" keyTimes="0;1" repeatCount="indefinite" />
                </circle>
              </svg>
          </h4>
        </div>
      </div>
    </div>
  `;
  pipWelcomePlaceholderElement.innerHTML = html;
  content_placeholder.appendChild(pipWelcomePlaceholderElement);
}

async function generateLogoPlaceholder(brand) {
  const logo_placeholder = document.getElementById("logo-placeholder");
  if (!logo_placeholder) return;
  const image = document.createElement("img");
  image.src = brand.logo_url;
  image.alt = brand.display_name;
  image.title = brand.display_name;
  image.classList.add("w-[16rem]");
  logo_placeholder.appendChild(image);

  // Check when image loaded
  return new Promise((resolve) => {
    image.addEventListener("load", () => {
      resolve();
    });

    image.addEventListener("error", () => {
      logo_placeholder.removeChild(image);
      localStorage.removeItem(BRAND_INFO_LOCAL_STORAGE_KEY);

      resolve();
    });
  });
}

function hideAppLoaderSpinner() {
  const app_loading_spinner = document.getElementById("app_loader_spinner");
  if (!app_loading_spinner) return;
  app_loading_spinner.classList.remove("flex");
  app_loading_spinner.classList.add("hidden");
}

function showAppLoaderSpinner() {
  const app_loading_spinner = document.getElementById("app_loader_spinner");
  if (!app_loading_spinner) return;
  app_loading_spinner.classList.remove("hidden");
  app_loading_spinner.classList.add("flex");
}

async function generatePlaceholderContent(brand) {
  const content_placeholder = document.getElementById("content-placeholder");

  if (!content_placeholder) return;

  await generateLogoPlaceholder(brand);
  switch (window.location.pathname) {
    case "/pip-login":
      pipWelcomePlaceholder(brand.colour);
      hideAppLoaderSpinner();
      break;
    default:
      content_placeholder.classList.add("items-center", "justify-center");
      break;
  }
}

// HACK: This is a temporary solution to get the brand data from the S3 bucket for new patient app domain
function getBrandsJsonFilename(host) {
  if (isPipPaired) {
    const pipData = window.localStorage.getItem(PIP_DATA_STORAGE_KEY);
    const siteUrl = pipData ? JSON.parse(pipData).siteUrl : "";
    if (!siteUrl) return "";
    const hostName = siteUrl.split("/")[2];
    return `${hostName}.json`;
  }

  return `${host}.json`;
}

function setBrandInfo(brandInfo) {
  window.localStorage.setItem(
    BRAND_INFO_LOCAL_STORAGE_KEY,
    JSON.stringify({
      ttl: Date.now() + 3600000, // 1 hour
      value: brandInfo,
    })
  ); // This should match the BRAND_INFO_LOCAL_STORAGE_KEY key
}

function getCachedBrandInfo() {
  const brandDataJson = window.localStorage.getItem(BRAND_INFO_LOCAL_STORAGE_KEY);

  if (!brandDataJson) {
    return null;
  }

  const brandData = JSON.parse(brandDataJson);

  if (brandData.ttl && brandData.ttl < Date.now()) {
    return null;
  }

  return brandData.value || null;
}

/**
 * Attempt to fetch the cached brand from S3 via Cloudfront
 */
async function fetchBrandData() {
  showAppLoaderSpinner();
  const { protocol, host } = window.location;
  let parsedBrandInfo = getCachedBrandInfo();

  if (!parsedBrandInfo) {
    try {
      const filename = getBrandsJsonFilename(host);
      if (!filename) return;

      const brandResponse = await fetch(`${protocol}//${host}/brands/${filename}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (brandResponse.status !== 200) throw new Error(`Error fetching brand data: ${brandResponse.status}`);
      parsedBrandInfo = await brandResponse.json();
    } catch (error) {
      console.info("Error fetching brand data - falling back to full domains call", error);
      return;
    }
  }

  setBrandInfo(parsedBrandInfo);

  if (parsedBrandInfo.logo_url && showContent()) {
    await generatePlaceholderContent(parsedBrandInfo);
    const contentWrapper = document.getElementById("content-wrapper");
    if (contentWrapper) {
      contentWrapper.classList.remove("hidden");
      contentWrapper.classList.add("flex");
    }
  }
}

/* Go to the root of the app if the user hits an error page. This will handle cases where the user has been redirected from old domains to the new domain
and the user hits an error page. As this code will be initialised before any Angular application code and any navigations to the error routes are handled by the Navigation service,
we can safely assume that this code will be hit only on refreshes or redirects as mentioned above. */
if (window.location.pathname === "/error") {
  window.location.pathname = "/";
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises
fetchBrandData();
